import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { MainState } from '../shared/states/main-state';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './components/layout/layout.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';



@NgModule({
  declarations: [LayoutComponent, LoginComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NgxsModule.forFeature([MainState])
  ], exports: [LayoutComponent, LoginComponent]
})
export class CoreModule { }
