import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { SetErrorMessage, UserLogged, UserLogout } from "../actions/main-actions";


/*
 * - negli stati di NGXS normalmente vanno messi i dati condivisi tra più componenti
 * - ogni feature module solitamente contiene un riferimento al MainState ed al suo State specifico
 */

export class MainStateModel {
    username?: string;
    token?: string;

    errorMsg?: string;
    errorDetail?: any;
}

@State<MainStateModel>({
    name: 'MainState',
    defaults: new MainStateModel()
})
@Injectable()
export class MainState {

    @Action(UserLogged)
    userLogged(ctx: StateContext<MainStateModel>, action: UserLogged) {
        ctx.patchState({
            username: action.username,
            token: action.token
        });

    }

    @Action(UserLogout)
    userLogout(ctx: StateContext<MainStateModel>, action: UserLogout) {
        ctx.patchState({
            username: undefined,
            token: undefined
        });

    }

    @Action(SetErrorMessage)
    setErrorMessage(ctx: StateContext<MainStateModel>, action: SetErrorMessage) {
        ctx.patchState({ errorMsg: action.errorMessage, errorDetail: action.errorDetail });
    }

    @Selector()
    static getErrorMsg(state: MainStateModel): string | undefined {
        return state.errorMsg;
    }

    @Selector()
    static getToken(state: MainStateModel): string | undefined {
        return state.token;
    }

    @Selector()
    static getUsername(state: MainStateModel): string | undefined {
        return state.username;
    }
}