import { Component, OnInit } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { SetErrorMessage } from 'src/app/shared/actions/main-actions';
import { MainState } from 'src/app/shared/states/main-state';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {


  @Select(MainState.getErrorMsg)
  errorMessage$!: Observable<string>;

  @Select(MainState.getUsername)
  userName$!: Observable<string>;

  destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(private toastController: ToastController, private navController: NavController, private store: Store) { }

  ngOnInit() {
    this.errorMessage$
      .pipe(
        takeUntil(this.destroy$), 
        filter(x => x !== undefined))
      .subscribe((errMsg) => {
        this.presentToast(errMsg);
        this.store.dispatch(new SetErrorMessage(undefined, undefined));
      });
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1500,
      position: 'top',
    });

    await toast.present();
  }

  navigateTo(url: string) {
    this.navController.navigateRoot([url]);
  }
}
