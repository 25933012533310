export class UserLogged  {
    static readonly type = '[Main] Logged';
    constructor(public username: string, public token: string) {}
}

export class UserLogout  {
    static readonly type = '[Main] Logout';
}

export class SetErrorMessage {
    static readonly type = '[Main] SetErrorMessage';
    constructor(public errorMessage: string | undefined, public errorDetail: any) {
    }
}