<ng-container *ngIf="!(userName$ | async); else logoutTpl" >
<form [formGroup]="formGroup">
  <ion-grid [fixed]="true">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size-md="6" size-lg="5" size-xs="12">
        <div text-center>
          <h3>Login</h3>
        </div>
        <div >
          <ion-item>
            <ion-input label="" name="username" placeholder="Username" formControlName="username" (keyup.enter)="login()"></ion-input>
          </ion-item>
          <ion-item>
            <ion-input label="" name="password" type="password"  placeholder="Password"  formControlName="password" (keyup.enter)="login()"></ion-input>
          </ion-item>
        </div>
          <ion-button size="large" type="submit" [disabled]="!(formGroup.valid && formGroup.dirty)" (click)="login()" expand="block">Login</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
</ng-container>
<ng-template #logoutTpl >
  <ion-grid [fixed]="true">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size-md="6" size-lg="5" size-xs="12" class="ion-text-center">
        <ion-item>
          <div class='center-button'>
            <ion-button size="large" type="submit" (click)="logout()">Logout</ion-button>
          </div>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>
