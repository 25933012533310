import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { environment } from 'src/environments/environment';
import { MainState } from './shared/states/main-state';
import { CoreModule } from './core/core.module';
import { FormsModule } from '@angular/forms';
import { SharedHttpInterceptor } from './core/services/http-interceptor.service';


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, 
    FormsModule,
    IonicModule.forRoot(), 
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    NgxsFormPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
    NgxsModule.forRoot([MainState], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),

  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: SharedHttpInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {}
