import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/components/layout/layout.component';
import { LoginComponent } from './core/components/login/login.component';

const routes: Routes = [
  {
    path: 'account-management', component: LayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./feature/account-management/account-management.module').then(m => m.AccountManagementModule) },
    ]
  },
  {
    path: 'garage-info', component: LayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./feature/garage-info/garage-info.module').then(m => m.GarageInfoModule) },
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    pathMatch: 'prefix',
    children: [
      { path: 'login', component: LoginComponent }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
