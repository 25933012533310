import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { WsResp } from 'src/app/shared/models/ws-resp.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(protected http: HttpClient) {
  }
  public async login(username: string, password: string): Promise<string | undefined> {
     const loginRes = await firstValueFrom(this.http.post<WsResp<void>>(`${environment.apiUrl}/api/v2/login`, null, {
      headers: new HttpHeaders({
        'X-Auth-Username': username,
        'X-Auth-Password': password
      }),
      observe: 'response'
    }));
    return loginRes.headers.get('X-Auth-Token') ?? undefined;
  }
}
