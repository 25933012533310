import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { CoreService } from '../../services/core.service';
import { UserLogged, UserLogout } from 'src/app/shared/actions/main-actions';
import { Observable } from 'rxjs';
import { MainState } from 'src/app/shared/states/main-state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formGroup!: FormGroup;
  @Select(MainState.getUsername)
  userName$!: Observable<string>;

  constructor(protected fb: FormBuilder, protected store: Store, protected coreService: CoreService) { }

  ngOnInit() {
    this.resetForm();
  }
  protected resetForm() {
    this.formGroup = this.fb.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  async login() {
    const token = await this.coreService.login(this.formGroup.controls['username'].value, this.formGroup.controls['password'].value);
    if (token) {
      this.store.dispatch(new UserLogged(this.formGroup.controls['username'].value, token));
      this.resetForm();
    }
  }


  logout() {
    this.store.dispatch(new UserLogout());
  }

}
