import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { MainState } from 'src/app/shared/states/main-state';
import { SetErrorMessage } from 'src/app/shared/actions/main-actions';

@Injectable({
  providedIn: 'root'
})
export class SharedHttpInterceptor implements HttpInterceptor {

  constructor(protected store: Store) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.store.selectSnapshot(MainState.getToken);

    const modifiedReq = token ? request.clone({
      headers: request.headers.set('X-Auth-Token', token),
    }) : request;

    return next.handle(modifiedReq).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
              this.store.dispatch(new SetErrorMessage('Errore nella richiesta', error.error));
              break;
            case 401:
              this.store.dispatch(new SetErrorMessage('Errore di autenticazione', error.error));
              break;
            case 403:
              this.store.dispatch(new SetErrorMessage('Permesso negato', error.error));
              break;
            case 500:
              this.store.dispatch(new SetErrorMessage('Errore server', error.error));
              break;
          }
        } else {
          // Handle non-HTTP errors
          this.store.dispatch(new SetErrorMessage('Errore nell\'applicazione', error));
          console.error('An error occurred:', error);
          // Perform generic error handling for non-HTTP errors
        }

        return throwError(() => error);
      })
    );
  }
}