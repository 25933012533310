<ion-split-pane [when]="false" contentId="main">
  <ion-menu side="start" contentId="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item (click)="navigateTo('garage-info')">Garage Info</ion-item>
      </ion-list>
      <ion-list>
        <ion-item (click)="navigateTo('login')">Login</ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>

  <div class="ion-page" id="main">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
          <ion-title>InPark 2</ion-title>
        </ion-buttons>
        <ion-buttons slot="end">
          <ng-container *ngIf="(userName$ | async) as username">
            <ion-button>
              <ion-icon slot="icon-only" name="person-circle"></ion-icon>
            </ion-button>
            <ion-label>{{username}}</ion-label>
          </ng-container>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-router-outlet id="main">

      </ion-router-outlet>
    </ion-content>
  </div>
</ion-split-pane>